body {
    &[lang="he"] {
        .LessonListItem__container--list-item {
        // direction: rtl;
        // transform-origin: 0 0;

        .Lesson_Instructor--component {
            
            .Lesson_Instructor--animal {
                transform: scaleX(-1);
            }
        }
      }
    }
  }


$LessonListItemBottomPadding : 45px;

.LessonListItem__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc($LessonListItemBottomPadding * -1);
    @media (max-width: 768px) {
        margin-top: 0px;
    }
    .LessonListItem__todo {
        color: white;
        padding-bottom: $LessonListItemBottomPadding;
        font-size: 18px;
        text-decoration: underline;
        max-width: 300px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        align-items: center;
        img {
            width: 20px;
        }
        @media (max-width: 768px) {
            padding-bottom: 30px;
        }
    }
}
.LessonListItem__container--list-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    position: relative;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        margin-bottom: 50px;
    }
    .LessonListItem__title {
        font-size: 24px;
        text-align: center;
        width: 100%;
        font-family: var(--mainFont);
        font-weight: 500;
        position: absolute;
        bottom: -64px;
    }
    .LessonListItem__title-current {
        color:white;
    }
    .lock-sticker {
        position: absolute;
        top:20px;
        inset-inline-end:20px;
        z-index: 2;
    }
    .lessonImg-container{
        position: relative;
        max-width: 300px;
        min-width: 300px;
        width: 100%;
        height: 300px;
        border-radius: 46px;
        display: flex;
        flex-direction: column;
        background-color: white;
        justify-content: center;
        align-items: center;
        gap: 20px;
        &:hover{
            .lessonImg{
            transform: scale(1.04);
            }
        }
        @media (max-width: 768px) {
            min-width: 0px;
        }
        .Lesson_Trophy{
            position: absolute;
            height: 160px;
            width: 143px;
            bottom: -44px;
            inset-inline-end: -64px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(-14deg);
            img {
                width: 100%;
                height: 100%;
                z-index: 2;
            }
            .glowCircle {
                z-index: 1;
                height: 90px;
                width: 90px;
                background-color: #f8ec00;
                content: " ";
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -25%);
                // animation: breathe 3s infinite ease-in-out; /* Add breathing animation */
                // filter: blur(3px);
                &::before {
                    z-index: 1;
                    height: 110px;
                    width: 110px;
                    background-color: #f8ec0063;
                    content: " ";
                    border-radius: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    animation: breatheBefore 3s infinite ease-in-out; /* Slightly different timing */
                }
            }
            
        }
        .lessonImg{
            transition: transform 140ms ease-in-out;
            width: 100%;
            height: 100%;
            object-fit: cover; 
            border-radius: 42px;
            // border: 12px solid rgba(0, 0, 0, 0.188);
            outline: 8px solid rgba(0, 0, 0, 0.188);
            outline-offset: 10px;
            // border: 12px solid red;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .lessonImg-container_overlay-tip {
            position: absolute;
            top:0;
            inset-block-start: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            border-radius: 46px;
            text-align: center;
            padding: 20px;
            font-size: 18px;
            z-index: 1;
        }
    }
    .lessonImg-container-disabled {
        background-color : rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.357);
        &:hover {
            .lessonImg {
                transform: scale(1);
            }
        }
        // opacity: 0.7;
        // filter: saturate(0%);
    }
    .lessonImg-container-locked {
        &:hover {
            .lessonImg {
                transform: scale(1);
            }
        }
        .lessonImg {
            outline: 8px dashed rgba(0, 0, 0, 0.188);
        }
    }
    
}
.LessonListItem__container_XL {
    .lessonImg-container {
        height: 420px;
        max-width: 420px;
        min-width: 420px;
        width: 100%;
        @media (max-width: 768px) {
            min-width: 0px;
        }
    }
    .Lesson_Instructor--component {
        bottom: -110px;
    }
}
.LessonListItem__container_completed {
    .LessonListItem__title {
        // color: black;
        color: rgba(255, 255, 255, 0.86);
    }
    // .LessonListItem__title{color: var(--color-)}
    .lessonImg-container .lessonImg{
        // border-color: var(--color-gold);
        // border: 
        // border: 10px solid var(--color-gold);
        outline: 8px solid var(--color-gold);

        // outline-offset: 2px;
        // border: none;
        // transform: scale(0.95);
        // outline: 3px solid #ffcb2271;
    }
}
.LessonListItem__container_current {

    .LessonListItem__title {
        // color: white;
        // // text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
        // text-shadow: 
        // -1px -1px 4px rgba(255, 255, 255, 0.3), 
        // 1px -1px 4px rgba(255, 255, 255, 0.3),  
        // -1px 1px 4px rgba(255, 255, 255, 0.3),  
        // 1px 1px 4px rgba(255, 255, 255, 0.3) ;
        }
        .lessonImg-container {
            // border: 6px solid rgb(255, 255, 255);
        }
    .lessonImg-container .lessonImg{
        // border: 6px solid rgba(255, 255, 255, 0);}
        outline: 8px solid white;
    }
        
}

.startHereTip {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: arrowPoint 3s infinite;
    position: absolute;
    top: -100px;
    transform: translateY(-100%);
    z-index: 2;
    .startHereTip-box {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        font-size: 24px;
        font-weight: 600;
    }
    .startHereTip-arrow {
        margin-top: -5px;
    }
}

@keyframes arrowPoint {
    0%, 100% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-12px);
      animation-timing-function: ease-in-out;
    }
  }


.ArrowPointer {
    content: "";
    background-size: contain;
    position: absolute;
    top: -100px;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 1;
    animation: arrowPoint 3s infinite;
    transform-origin: bottom;
    filter: drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.3));
    img{
        height: 20px;
    }
}

@keyframes pointer {
    0%, 100% {
      top: 0px;
    //   animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
      transform: scaleY(1) scaleX(1);
    }
    
    50%{
        top: -26px;
        animation-timing-function: ease-in-out;
        transform: scaleY(1.05) scaleX(1.2);

    }
}


/* Breathing Animation for .glowCircle */
@keyframes breathe {
    50% {
        transform: translate(-50%, -45%) scale(1);
        opacity: 0.5;
    }
    0%, 100%{
        transform: translate(-50%, -45%) scale(0.94);
        opacity: 0.7;
    }
}

/* Breathing Animation for .glowCircle::before */
@keyframes breatheBefore {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 0;
    }
}


@keyframes show-tip {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .tip {
    animation: show-tip 4s forwards;
  }